import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IIdentity } from '../../auth/core/interfaces';
import { LocalStorageService } from '@cinetixx/cinetixx-ui';

@Injectable({
  providedIn: 'root'
})
export class IdentityService {

  private readonly _storageKey = 'identity';
  private readonly _isAuthenticated$ = new BehaviorSubject(!!this.identity);

  public constructor(
    private readonly _localStorageService: LocalStorageService
  ) {}

  public get isAuthenticated$(): Observable<boolean> {
    return this._isAuthenticated$.asObservable();
  }

  public get isAuthenticated(): boolean {
    return this._isAuthenticated$.getValue();
  }

  public get identity(): IIdentity {
    return this._localStorageService.getItem(this._storageKey);
  }

  public get mandatorId(): number {
    return +this.identity.claims['mandatorId'];
  }

  public set identity(identity: IIdentity) {
    this._localStorageService.setItem(this._storageKey, identity);
    this._isAuthenticated$.next(true);
  }

  public get isAdmin(): boolean {
    return this.identity.claims['isAdmin'] === 'True';
  }

  public destroyIdentity(): void {
    this._localStorageService.removeItem(this._storageKey);
    this._isAuthenticated$.next(false);
  }
}
