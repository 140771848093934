import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from "@angular/router";
import { Observable, Subject, tap } from "rxjs";
import { ILanguagesAndSnippets } from "../../../core/interfaces";
import { environment } from "src/environments/environment";
import { ISnippet } from "../interfaces";
import { SnippetsService } from '../services/SnippetsService';
import { LanguageStoreService } from '../services/LanguageStoreService';
import { SnippetsStoreService } from '../services/SnippetsStoreService';
import { ILanguage } from "@cinetixx/cinetixx-ui";



export const languagesSnippets$ = new Subject<ILanguagesAndSnippets>();

export const languagesSnippetsResolver: ResolveFn<ILanguagesAndSnippets> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  snippetsService = inject(SnippetsService),
  languageStoreService = inject(LanguageStoreService),
  snippetsStoreService = inject(SnippetsStoreService),
): Observable<ILanguagesAndSnippets> => {
  const getAllLanguages$ = (): Observable<ILanguage[]> => {
    const { fallbackLanguage } = environment;

    return snippetsService.getAllLanguages().pipe(
      tap(languages => {
        const language = route.params['language'];
        let fLanguage = languages.find(x => x.token === language);

        if (!fLanguage && language !== fallbackLanguage) {
          fLanguage = languageStoreService.language ?? languages.find(x => x.token === fallbackLanguage);
          location.href = `/${ fLanguage.token }${ state.url }`;
        }

        languageStoreService.language = fLanguage;
      })
    );
  };
  const getAllSnippets$ = (): Observable<ISnippet[]> => {
    return snippetsService.getAllSnippets(languageStoreService.language.token).pipe(
      tap(snippets => snippetsStoreService.snippets = snippets)
    );
  };

  getAllLanguages$().subscribe(languages => {
    if (languageStoreService.language) {
      getAllSnippets$().subscribe(snippets => {
        languagesSnippets$.next({
          languages,
          snippets
        });
      });
    }
  });

  return languagesSnippets$.asObservable();
};
