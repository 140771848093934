import { HttpParams } from '@angular/common/http';
import { Dictionary, Filter, Search, SortingTypes } from '@cinetixx/cinetixx-ui';

export class HttpUtils {
  public static prepareQueryableParams(
    page: number,
    results: number,
    sortField: string,
    sortOrder: SortingTypes,
    search: Search = [],
    filter: Filter = [],
    dictionary: Dictionary = [],
  ): HttpParams {

    let params = new HttpParams({
      fromObject: {
        page: page.toString(),
        results: results.toString()
      }
    });

    if (sortOrder && sortField) {
      params = params.append('sortOrder', sortOrder).append('sortField', sortField);
    }

    if (search.length > 0) {
      search.forEach(el => {
        if (el.value) {
          el.value.forEach(value => params = params.append('Search', `${ el.key }:${ value }`));
        }
      });
    }

    if (filter.length > 0) {
      filter.forEach(el => {
        if (el.value) {
          el.value.forEach(value => params = params.append('Filter', `${ el.key }:${ value }`));
        }
      });
    }

    if (dictionary.length > 0) {
      dictionary.forEach(el => {
        params = params.append('Dictionary', `${ el.key }:${ el.value.join(':') }`);
      });
    }

    return params;
  }
}
