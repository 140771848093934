export enum StoreCacheKeys {
  GetAllLanguages = 'getAllLanguages',
  GetAllSnippets = 'getAllSnippets',
  GetDistributors = 'getDistributors',
  GetLatest = 'getLatest',
  GetGenres = 'getGenres',
  GetAgeRatings = 'getAgeRatings',
  GetVersionTypes = 'getVersionTypes',
  GetCastTypes = 'getCastTypes',
  GetMovieTypes = 'getMovieTypes',
  GetMyProfile = 'getMyProfile',
  GetMyAccount = 'getMyAccount',
  GetDashboard = 'getDashboard',
  GetMyCinemas = 'getMyCinemas',
  GetStatisticsSettings = 'getStatisticsSettings',
  GetMyCinemasWithAuditoriums = 'getMyCinemasWithAuditoriums',
  GetMyAuditoriums = 'getMyAuditoriums',
  GetActiveCashSessions = 'getActiveCashSessions',
  GetSalesTax = 'getSalesTax',
  GetPossibleTimeOptions = 'getPossibleTimeOptions',
  GetSalesTaxes = 'getSalesTaxes',
  GetShowTypes = 'getShowTypes',
  GetAccountRegions = 'getAccountRegions',
  GetNotedMovies = 'getNotedMovies',
  GetCountries = 'getCountries',
  GetCurrencies= 'getCurrencies',
  GetAllCinemas = 'getAllCinemas',
  GetVoucherNames = 'getVoucherNames',
  GetTabCardsNames = 'getTabCardsNames',
  TerminalsIdAndName = 'terminalsIdAndName',
  MinCashMigrationVersion = 'minCashMigrationVersion',
  TabPriceNames = 'getTabPriceNames',
  CardTypes = 'getCardTypes',
  GetCountriesMovie = 'getCountriesMovie',
  GetKiosks = 'getKiosks',
  GetLatestReleaseNotes = 'getLatestReleaseNotes',
  GetTableExports = 'getTableExports',
  GetKiosksProductGroup = 'getKiosksProductGroup',
  GetKiosksPriceCategories = 'getKiosksPriceCategories',
  GetKioskActionsTypes = 'getKioskActionsTypes',
  GetLanguages = 'getLanguages',
  GetTerminalTypes = 'getTerminalTypes',
  GetKioskLayoutTypes = 'getKioskLayoutTypes',
  GetSeatTypes = 'getSeatTypes',
  GetDefaultSeatStatuses = 'getDefaultSeatStatuses',
  GetAuditoriumTypes = 'getAuditoriumTypes',
  GetMandatorFirms = 'getMandatorFirms',
  GetPhoneTypes = 'getPhoneTypes',
  GetCities = 'getCities',
  GetTicketFees = 'getTicketFees',
}
